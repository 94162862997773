import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import rest from './plugins/rest'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './registerServiceWorker'

Vue.use(VueAxios, axios)
Vue.use(rest)

Vue.config.productionTip = false;

const hasPermission = (auth, restPolicies, term) => {
  if(auth) {
    const groups = auth.groups || [];

    if(term.toLowerCase().startsWith('cn=') || term.toLowerCase().startsWith('ou=') || term.toLowerCase().startsWith('dc=')) {
      for(let group of groups) {
        if(group.toLowerCase().startsWith(term.toLowerCase())) {
          return true;
        }
      }
    }

    if(restPolicies) {
      let policies = [];
      
      if(restPolicies[term]) {
        policies = restPolicies[term.toLowerCase()];
      } 
      
      if(policies.length == 0) {
        for(let policy in restPolicies) {
          if(term.toLowerCase().startsWith(policy.toLowerCase())) {
            policies = restPolicies[policy];
          }
        }
      }

      if(policies.length == 0) {
        if(term.toLowerCase().startsWith('get:')) term = term.substring(4);
        if(term.toLowerCase().startsWith('post:')) term = term.substring(5);
        if(term.toLowerCase().startsWith('delete:')) term = term.substring(7);

        for(let policy in restPolicies) {
          if(term.toLowerCase().startsWith(policy.toLowerCase())) {
            policies = restPolicies[policy];
          }
        }
      }
  
      for(let policy of policies) {
        for(let group of groups) {
          if(group.toLowerCase().startsWith(policy.toLowerCase())) {
            return true;
          }
        }
      }
    }
  }

  return false;
}

router.beforeEach(async (to, from, next) => {
  store.dispatch("startLoading");
  
  const authToken = localStorage.getItem("authToken") || "";
  const requiresAuth = to.matched.some(record => record.name != "login");

  let policies = {};

  if(authToken != "" && navigator.onLine) {
    let restRouteInfos = await rest.get("/_", {}, { authToken });
    if(restRouteInfos) {
      for(let info of restRouteInfos) {
        let groups = [];
        
        for(let part in info.authentication) {
          let groupsB = [];

          for(let group of info.authentication[part]) {
            if(!groups.includes(group)) groups.push(group.toLowerCase());
            groupsB.push(group.toLowerCase());
          }

          if(groupsB.length > 0) {
            const base = info.base == "/" ? "" : info.base;
            const [ method, path ] = part.split(':');
            const route = (method + ':' + base + path).replace(/\/$/g, '').toLowerCase();

            if(route.substring(0, 2) != "*:") policies[route] = groupsB;
          }
        }

        policies[info.base.toLowerCase()] = groups;
      }
    }
    
    store.commit("restPolicies", policies);
  }

  if (requiresAuth) {
    if(authToken != "") {
      if(navigator.onLine) {
        let auth = await rest.post("/authenticate", null, { authToken });
        if(auth) {
          if(hasPermission(auth, policies, '/project')) {
            auth.project = (await rest.get('/project/users/' + auth.initials, {}, { authToken })) || null;
            store.commit("auth", auth);
          }
          next();
          return;
        }
      } else {
        if(store.state.auth != null && store.state.auth.authToken == authToken) {
          next();
          return;
        }
      }
    }
  } else {
    //store.commit("auth", null);
    next();
    return;
  }

  next({ name: 'login', query: { from: to.path } });
});

router.afterEach(() => {
  store.dispatch("stopLoading");
});

store.commit('initialiseStore');

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    window.addEventListener('online', () => { 
      this.$store.commit('isOnline', true);
    });
    window.addEventListener('offline', () => { 
      if(this.$store.state.isOnline) this.$store.commit('isOnline', false);
    });
    window.addEventListener('swOffline', () => { 
      if(this.$store.state.isOnline) this.$store.commit('isOnline', false);
    });
  },
  methods: {
    hasPermission(term) {
      return hasPermission(store.state.auth, store.state.restPolicies, term);
    },
    hardRefresh() {
      location.reload(true);
    }
  }
}).$mount('#app');

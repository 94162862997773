<template>
  <v-app class="background">
    <v-main>
      <router-view v-if="!isLoading"/>
    </v-main>

    <v-dialog v-model="showLoading" width="80%" max-width="10rem" persistent>
      <v-card>
        <v-card-title class="flex-column align-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-card-title>
        <v-card-text class="text-center">
          Bitte warten...
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar bottom :value="swUpdateExists" :timeout="-1">
      Neue Version verfügbar
      <v-btn text @click="swRefreshApp">
        Aktualisieren
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      swRegistration: null,
      swUpdateExists: false
    }
  },
  computed: {
    isLoading() { return this.$store.state.isLoading == 1 },
    showLoading() { return this.$store.state.isLoading > 0 }
  },
  created() {
    if(navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', event => {
        if (this.swUpdateExists) return;
        this.swUpdateExists = true;
      });
    }
  },
  methods: {
    swRefreshApp() {
      window.location.reload();
    }
  }
};
</script>

<style>
#app, .background {
  background: #f0f0f0;
  background-image: url("assets/background-light.jpg");
  background-size: cover;
}

.v-footer {
  border-width: thin 0 0 0 !important;
  border-style: solid !important;
}

.theme--light.v-footer {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.v-tooltip__content > p:last-child {
  margin-bottom: 0;
}
</style>
<template>
  <defaultLayout class="home">
    <v-row class="justify-center">
      <v-col cols="12" md="6" v-if="$root.hasPermission('/starface')">
        <emergency-phone></emergency-phone>
      </v-col>
      <v-col cols="12" md="6" v-if="$root.hasPermission('cn=vpn-users')">
        <open-vpn></open-vpn>
      </v-col>
      <v-col cols="12" v-if="$root.hasPermission('get:/resources/computers')">
        <client-list></client-list>
      </v-col>
    </v-row>
  </defaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue'
import EmergencyPhone from '@/components/EmergencyPhone.vue'
import OpenVpn from '@/components/OpnVpn.vue'
import ClientList from '@/components/ClientList.vue'

export default {
  components: { DefaultLayout, EmergencyPhone, OpenVpn, ClientList }
}
</script>

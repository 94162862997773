<template>
  <div>
    <v-app-bar app color="primary" dark v-if="auth">
      <v-toolbar-title v-if="$root.hasPermission('/utilities')"><span class="grey--text text--lighten-2 subtitle-1">Öffentliche IP:</span> {{ publicIp }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="d-none d-sm-flex">
        {{ auth.name }}
      </v-toolbar-title>
      <v-toolbar-title>
        <v-menu bottom :close-on-content-click="false" transition="slide-y-transition" min-width="18rem" max-width="18rem">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark small class="ml-2" v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item link :to="{ name: 'dashboard'}">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item>
            
            <v-list-item link :to="{ name: 'users'}" v-if="$root.hasPermission('get:/resources/users')">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Personen</v-list-item-title>
            </v-list-item>

            <v-list-group :value="$route.matched.some(({ name }) => name === 'project')" v-if="$root.hasPermission('/project')">
              <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-package-variant-closed</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Project</v-list-item-title>
              </template>

              <v-list-item link :to="{ name: 'project_activeSessions'}">
                <v-list-item-icon class="subtree-icon">
                  <v-icon>mdi-arrow-right-bottom</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Aktive Sitzungen</v-list-item-title>
              </v-list-item>

              <v-list-item link :to="{ name: 'project_addressList'}">
                <v-list-item-icon class="subtree-icon">
                  <v-icon>mdi-arrow-right-bottom</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Adressenstamm</v-list-item-title>
              </v-list-item>

              <v-list-item link :to="{ name: 'project_printManagement'}">
                <v-list-item-icon class="subtree-icon">
                  <v-icon>mdi-arrow-right-bottom</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Druckeinstellungen</v-list-item-title>
              </v-list-item>

              <!--
              <v-list-item link :to="{ name: 'project_projects'}">
                <v-list-item-icon class="subtree-icon">
                  <v-icon>mdi-arrow-right-bottom</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Projekte</v-list-item-title>
              </v-list-item>
              -->

              <v-list-item link :to="{ name: 'project_outgoingInvoices'}">
                <v-list-item-icon class="subtree-icon">
                  <v-icon>mdi-arrow-right-bottom</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Rechnungsausgang</v-list-item-title>
              </v-list-item>

              <!--
              <v-list-item link :to="{ name: 'project_allowance'}">
                <v-list-item-icon class="subtree-icon">
                  <v-icon>mdi-arrow-right-bottom</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Aufmaß</v-list-item-title>
              </v-list-item>
              -->
            </v-list-group>

            <v-list-item link :to="{ name: 'defender_overview'}" v-if="$root.hasPermission('CN=Domänen-Admins') || $root.hasPermission('CN=Administratoren')">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-shield-half-full</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Defender</v-list-item-title>
            </v-list-item>

            <v-list-item link :to="{ name: 'cams'}" v-if="$root.hasPermission('CN=security-cams') || $root.hasPermission('CN=Domänen-Admins') || $root.hasPermission('CN=Administratoren')">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-cctv</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Überwachungskameras</v-list-item-title>
            </v-list-item>

            <v-list-item link :to="{ name: 'stats'}" v-if="$root.hasPermission('/measurements')">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-finance</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Statistiken</v-list-item-title>
            </v-list-item>
            
            <v-list-item link @click="logout">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Abmelden</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>
            <v-layout align-center>
              <v-subheader class="mr-0 pr-0">Version: {{ $store.getters.packageVersion }} ({{ $store.getters.channel }})</v-subheader>
              <v-spacer></v-spacer>
              <v-btn icon class="mr-3" @click="$root.hardRefresh"><v-icon>mdi-refresh-circle</v-icon></v-btn>
            </v-layout>
          </v-list>
        </v-menu>
      </v-toolbar-title>
    </v-app-bar>

    <v-container class="pt-6">
      <slot></slot>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    publicIp: "0.0.0.0"
  }),
  computed: {
    auth() { return this.$store.state.auth },
    restPolicies() { return this.$store.state.restPolicies },
    isOnline() { return this.$store.state.isOnline }
  },
  async mounted() {
    if(this.$root.hasPermission("/utilities")) {
      this.publicIp = (await this.$rest.get("/utilities/ip", {}, { authToken: this.auth.authToken })) || "0.0.0.0";
    }
  },
  methods: {
    async logout() {
      await this.$rest.delete("/authenticate", { authToken: this.auth.authToken });
      localStorage.removeItem("authToken");
      this.$router.replace({ name: 'login' });
    }
  }
};
</script>

<style scoped>
.subtree-icon {
  margin-top: .5em !important;
  margin-left: .5em !important;
  margin-right: .8em !important;
}
</style>
<template>
  <v-container class="background d-flex align-center" style="height: 100%; max-width: 100%">
    <v-card class="flex-grow-1" style="max-width: 40em; margin: 0 auto">
      <v-card-title class="justify-center">
        <img src="@/assets/logo.svg" class="flex-grow-1">
      </v-card-title>

      <v-card-text>
        <v-text-field v-model="username" label="Benutzername" @keyup.enter="login" outlined></v-text-field>
        <v-text-field v-model="password" label="Passwort" type="password" @keyup.enter="login" outlined></v-text-field>


        <div class="d-flex">
          <v-subheader class="red--text pl-0">{{ errorMessage }}</v-subheader>
          <v-spacer></v-spacer>
          <v-btn color="primary" large @click="login">Anmelden</v-btn>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-subheader>Version: {{ $store.getters.packageVersion }} ({{ $store.getters.channel }})</v-subheader>
    </v-card>
  </v-container>
</template>

<script>
let errorTimeout = 0;

export default {
  data:() => ({
    username: "",
    password: "",
    errorMessage: ""
  }),
  methods: {
    async login() {
      clearTimeout(errorTimeout);
      this.errorMessage = "";

      if(this.username == "") {
        this.errorMessage = "Benutzername erforderlich";
        errorTimeout = setTimeout(() => { this.errorMessage = "" }, 2000);
        return;
      }

      if(this.password == "") {
        this.errorMessage = "Passwort erforderlich";
        errorTimeout = setTimeout(() => { this.errorMessage = "" }, 2000);
        return;
      }

      let auth = await this.$rest.post("/authenticate", { username: this.username, password: this.password });
      if(auth) {
        localStorage.setItem("authToken", auth.authToken);
        this.$store.commit("auth", auth);
        this.$router.replace(this.$route.query.from || "/");
      } else {
        this.errorMessage = "Anmeldung fehlgeschlagen";
        errorTimeout = setTimeout(() => { this.errorMessage = "" }, 2000);
      }
    }
  }
}
</script>

<style scoped>
.background {
  background-image: url("@/assets/background.jpg") !important;
}
</style>
<template>
  <div>
    <v-card :disabled="!$parent.isOnline">
      <v-card-title>
        <v-icon large left>mdi-vpn</v-icon>
        OpenVpn
      </v-card-title>

      <v-card-text v-if="$parent.isOnline">
        <v-list rounded class="pa-0">
          <v-list-item class="client-item">
            <v-list-item-icon>
              <v-icon>mdi-application</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>OpenVpn Connect Client v 3.1.2</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon small :href="$rest.url + '/vpn/download/client'">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item class="client-item">
            <div class="d-flex flex-column flex-grow-1" style="max-width: 100%">
              <div class="d-flex flex-grow-1">
                <v-list-item-icon>
                  <v-icon>mdi-puzzle</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Profil - Meyer Haustechnik Nienburg</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon small @click="getProfileLink">
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </v-list-item-action>
              </div>
              <div class="d-flex flex-grow-1" v-if="link != ''">
                <v-btn icon small @click="link = ''" class="mr-2 mt-1">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-text-field outlined dense hint="Der generierte Link ist 5 Minuten gültig" persistent-hint readonly v-model="link"/>
                <v-btn icon small @click="copyProfileLink" class="ml-2 mt-1">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn icon small @click="downloadProfile" class="ml-2 mt-1">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-text v-else>
        Offline nicht verfügbar
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data:() => ({
    showCopy: false,
    snackbar: false,
    snackbarText: "",
    link: ""
  }),
  mounted() {
    this.showCopy = navigator.clipboard != undefined;
  },
  methods: {
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.snackbarText = "Link erfolgreich kopiert";
          this.snackbar = true;
        }, (error) => {
          console.error(error);
          this.snackbarText = "Link konnte nicht kopiert werden";
          this.snackbar = true;
        });
      } else {
        console.error('Copy to clipboard not supported');
        this.snackbarText = "Link konnte nicht kopiert werden";
        this.snackbar = true;
      }
    },
    async getProfileLink() {
      let url = await this.$rest.post("/vpn/download/profile", null, { authToken: this.$parent.auth.authToken });
      
      if(url) {
        this.link = this.$rest.url + "/vpn/download/profile/" + url;
      } else {
        this.snackbarText = "Link konnte nicht erstellt werden";
        this.snackbar = true;
      }
    },
    copyProfileLink() {
      if(this.link != '') {
        this.copyToClipboard(this.link.replace(/^https?:\/\//, ''));
      }
    },
    downloadProfile() {
      if(this.link != '') {
        window.open(this.link, "_blank").focus();
      }
    }
  }
}
</script>

<style scoped>
.client-item {
  background: #eee;
}

.client-item:hover {
  background: #e0e0e0;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import LoginView from '../views/LoginView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/UsersView.vue')
  },
  {
    path: '/stats',
    name: 'stats',
    component: () => import('../views/StatsView.vue')
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/ProjectView.vue'),
    children: [
      {
        path: 'activeSessions',
        name: 'project_activeSessions',
        component: () => import('../components/project/ActiveSessions.vue')
      },
      {
        path: 'addressList',
        name: 'project_addressList',
        component: () => import('../components/project/AddressList.vue')
      },
      {
        path: 'printManagement',
        name: 'project_printManagement',
        component: () => import('../components/project/PrintManagement.vue')
      },
      {
        path: 'projects',
        name: 'project_projects',
        component: () => import('../components/project/ProjectList.vue')
      },
      {
        path: 'allowance',
        name: 'project_allowance',
        component: () => import('../components/project/AllowanceView.vue')
      },
      {
        path: 'outgoingInvoices',
        name: 'project_outgoingInvoices',
        component: () => import('../components/project/OutgoingInvoices.vue')
      }
    ]
  },
  {
    path: '/defender',
    name: 'defender',
    component: () => import('../views/DefenderView.vue'),
    children: [
      {
        path: 'overview',
        name: 'defender_overview',
        component: () => import('../components/defender/DefenderOverview.vue')
      },
      {
        path: ':hostName',
        name: 'defender_client',
        component: () => import('../components/defender/ClientDetails.vue'),
      }
    ]
  },
  {
    path: '/cams',
    name: 'cams',
    component: () => import('../views/SecurityCams.vue')
  },
  { 
    path: '*', 
    redirect: '/dashboard' 
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ y: 0, behavior: 'smooth' });
      }, 200)
    });
  }
})

export default router

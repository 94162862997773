<template>
  <div>
    <v-card :disabled="!$parent.isOnline">
      <v-card-title>
        <v-icon large left>mdi-desktop-mac</v-icon>
        Computer
      </v-card-title>

      <v-card-text v-if="$parent.isOnline">
        <v-list rounded class="pa-0">
          <v-list-item v-for="client in clients" :key="client.mac.join(',')" class="client-item">
            <v-list-item-icon v-if="$root.hasPermission('post:/utilities/ping')" class="align-self-center">
              <v-icon v-if="client.alive" color="green">mdi-checkbox-blank-circle</v-icon>
              <v-icon v-else color="red darken-2">mdi-checkbox-blank-circle-outline</v-icon>
            </v-list-item-icon>
          
            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip bottom open-delay="500" color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ client.cn }}</span>
                  </template>

                  <v-simple-table dense dark class="transparent-tooltip">
                    <tbody>
                      <tr>
                        <td>Mac:</td>
                        <td>{{ client.mac.join(', ') }}</td>
                      </tr>
                      <tr>
                        <td>HostName:</td>
                        <td>{{ client.dNSHostName }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle v-if="client.managedBy || client.location" class="d-none d-sm-flex">
                <v-row>
                  <v-col :cols="client.location ? 6 : 12" v-if="client.managedBy"><span class="ellipsis">Hauptbenutzer: {{ client.managedBy.displayName }}</span></v-col>
                  <v-col :cols="client.managedBy ? 6 : 12" v-if="client.location"><span class="ellipsis">Ort: {{ client.location }}</span></v-col>
                </v-row>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="client.managedBy" class="d-flex d-sm-none"><span class="ellipsis">HB: {{ client.managedBy.displayName }}</span></v-list-item-subtitle>
              <v-list-item-subtitle v-if="client.location" class="d-flex d-sm-none"><span class="ellipsis">Ort: {{ client.location }}</span></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action v-if="$root.hasPermission('post:/utilities/ping')" class="ml-4">
              <v-list-item-action-text>Ping: {{ Math.round(client.ping) }}ms</v-list-item-action-text>
            </v-list-item-action>

            <v-list-item-action v-if="$root.hasPermission('CN=Domänen-Admins') || $root.hasPermission('CN=Administratoren')" class="ml-1">
              <defender-quick-status :client="client"></defender-quick-status>              
            </v-list-item-action>

            <v-list-item-action v-if="$root.hasPermission('post:/utilities/wakeup')" class="ml-1">
              <v-progress-circular indeterminate color="primary" v-if="client.pending" size="20" width="2" class="ma-1"></v-progress-circular>
              <v-btn v-else icon small :disabled="client.alive || client.pending || !client.firstPingArrived" @click="wakeup(client)">
                <v-icon>mdi-power</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-text v-else>
        Offline nicht verfügbar
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import DefenderQuickStatus from '@/components/defender/QuickStatus.vue'

let pingTimeout = 0;
let storeUnwatch;

export default {
  components: { DefenderQuickStatus },
  data:() => ({
    snackbar: false,
    snackbarText: "",
    clients: []
  }),
  mounted() {
    if(this.$parent.isOnline) this.run();
    storeUnwatch = this.$store.watch(state => state.isOnline, isOnline => {
      if(isOnline) this.run();
      else clearTimeout(pingTimeout);
    })
  },
  methods: {
    async run() {
      this.snackbar = false;
      this.clients = [];

      let hosts = [];
      let data = await this.$rest.get('/resources/computers', {}, { authToken: this.$parent.auth.authToken });
      if(data) {
        for(let d of data) {
          hosts.push(d.dNSHostName);
          this.clients.push(Object.assign({
            mac: d.macAddress.split(','),
            alive: false,
            ping: 0,
            pending: true,
            firstPingArrived: false
          }, d));
        }
      }

      let ping = async () => {
        let data = await this.$rest.post("/utilities/ping", { hosts }, { authToken: this.$parent.auth.authToken });

        for(let client of this.clients){
          if(data[client.dNSHostName] != undefined) {
            client.alive = data[client.dNSHostName].alive;
            client.ping = isNaN(data[client.dNSHostName].time) ? 0 : data[client.dNSHostName].time;
          }else{
            client.alive = false;
            client.ping = 0;
          } 

          if(!client.firstPingArrived) {
            client.firstPingArrived = true;
            client.pending = false;
          }
        }
        
        pingTimeout = setTimeout(ping, 2000);
      };

      if(this.$root.hasPermission("post:/utilities/ping")) ping();
    },
    async wakeup(client) {
      if(client && client.mac) {
        client.pending = true;
        let someError = false;
        for(let mac of client.mac) {
          if((await this.$rest.post("/utilities/wakeup", { mac }, { authToken: this.$parent.auth.authToken })) == false) {
            someError = true;
          }
        }

        this.snackbarText = "Es kann etwas dauern bis der PC erreichbar ist.";
        this.snackbar = true;
        if(someError) console.log("Manche Mac-Addressen führten zu einem Problem");

        setTimeout(() => { client.pending = false; }, 60000); // Funktion wird für eine Minute blockiert
      }
    }
  },
  beforeDestroy() {
    clearTimeout(pingTimeout);
    storeUnwatch();
  }
}
</script>

<style scoped>
.client-item {
  background: #eee;
}

.client-item:hover {
  background: #e0e0e0;
}

.transparent-tooltip {
  background: none !important;
}

span.ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
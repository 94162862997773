import axios from 'axios'
import store from '../store'

let url = "https://manager-rest.mht-n.de";

switch(store.getters.channel.toString().toLowerCase()) {
  case 'preview':
    url = "https://manager-rest.pre.mht-n.de";
    break;
  case 'development':
    url = "https://manager-rest.dev.mht-n.de";
    break;
}

export default {
  url,
  async get(endpoint, data, headers) {
    try {
      let response = await axios.get(url + endpoint, { params: data || {}, headers: headers || {} });
      if(response.status == 200) {
        return response.data || "OK";
      } else {
        console.log(response);
      }
    } catch(error) {
      console.log(error);
    }

    return null;
  },
  async post(endpoint, data, headers) {
    try {
      let response = await axios.post(url + endpoint, data || {}, { headers: headers || {} });
      if(response.status == 200) {
        return response.data || "OK";
      } else {
        console.log(response);
      }
    } catch(error) {
      console.log(error);
    }

    return null;
  },
  async put(endpoint, data, headers) {
    try {
      let response = await axios.put(url + endpoint, data || {}, { headers: headers || {} });
      if(response.status == 200) {
        return response.data || "OK";
      } else {
        console.log(response);
      }
    } catch(error) {
      console.log(error);
    }

    return null;
  },
  async delete(endpoint, headers) {
    try {
      let response = await axios.delete(url + endpoint, { headers: headers || {} } );
      if(response.status == 200) {
        return response.data || "OK";
      } else {
        console.log(response);
      }
    } catch(error) {
      console.log(error);
    }

    return null;
  },
  install: function(Vue) {
    Object.defineProperty(Vue.prototype, '$rest', { value: this });
  }
}
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

var t_isLoading = -1;

export default new Vuex.Store({
  state: {
    isLoading: 0,
    isOnline: navigator.onLine,
    auth: null,
    restPolicies: {},
    packageVersion: process.env.PACKAGE_VERSION,
    channel: process.env.CHANNEL
  },
  getters: {
    packageVersion: (state) => state.packageVersion,
    channel: (state) => state.channel
  },
  mutations: {
    isLoading (state, val) {
      state.isLoading = val;
    },
    isOnline (state, val) {
      state.isOnline = val;
    },
    auth (state, val) {
      localStorage.setItem('auth', JSON.stringify(val));
      state.auth = val;
    },
    restPolicies (state, val) {
      localStorage.setItem('restPolicies', JSON.stringify(val));
      state.restPolicies = val;
    },
    initialiseStore(state) {
      if (localStorage.getItem('auth')) state.auth = JSON.parse(localStorage.getItem('auth'));
      if (localStorage.getItem('restPolicies')) state.restPolicies = JSON.parse(localStorage.getItem('restPolicies'));
    }
  },
  actions: {
    startLoading({ commit }) {
      clearTimeout(t_isLoading);
      t_isLoading = -1;
      commit('isLoading', 1);
    },
    startSoftLoading({ commit }) {
      if(t_isLoading < 0) {
        commit('isLoading', 2);
      }
    },
    stopLoading({ commit }) {
      if(t_isLoading < 0) {
        commit('isLoading', 2);
        t_isLoading = setTimeout(() => {
          commit('isLoading', 0);
          t_isLoading = -1;
        }, 600);
      }
    }
  },
  modules: {
  }
})

<template>
  <div>
    <v-card :disabled="!$parent.isOnline || loading || !loggedIn" :loading="$parent.isOnline && loading">
      <v-card-title>
        <v-icon large left>mdi-phone-alert</v-icon>
        Notruftelefon - Elektro Goslar GmbH
      </v-card-title>
      <v-card-text v-if="$parent.isOnline">
        <v-radio-group v-model="emergency_receiver" v-if="$root.hasPermission('delete:/starface/fmcPhones') && $root.hasPermission('post:/starface/fmcPhones')">
          <template v-slot:label>
            <div>Wählen Sie den <strong>Empfänger</strong> aus</div>
          </template>
          <v-radio v-for="(value, key) in emergency_receivers" :key="key" :label="value + (key != '' ? ' (' + key + ')' : '')" :value="key"></v-radio>
        </v-radio-group>
        <div v-else>
          <div class="text-subtitle-1">Aktueller Empfänger:</div>
          <div class="text-h6">{{ emergency_receiver && emergency_receivers[emergency_receiver] ? emergency_receivers[emergency_receiver] + ' (' + emergency_receiver + ')' : "Niemand" }}</div>
        </div>
      </v-card-text>
      <v-card-text v-else>
        Offline nicht verfügbar
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
let unwatch;
let storeUnwatch;

export default {
  data:() => ({
    loggedIn: false,
    loading: false,
    emergency_receivers: {
      "": "Niemand",
      "01623431106": "Jan Hägermann",
      "01622045171": "Gerhard Wortmann"
    },
    emergency_receiver: "",
    emergency_receiverId: 0
  }),
  mounted() {
    if(this.$parent.isOnline) this.run();
    storeUnwatch = this.$store.watch(state => state.isOnline, isOnline => {
      if(isOnline) this.run();
      else unwatch();
    })
  },
  methods: {
    async run() {
      this.loading = true;

      if(await this.$rest.post("/starface/login", null, { authToken: this.$parent.auth.authToken }) != null) {
        let phones = await this.$rest.get("/starface/fmcPhones?userId=1139", {}, { authToken: this.$parent.auth.authToken });
        
        if(phones && phones.length > 0) {
          if(this.emergency_receivers[phones[0].number] == undefined) {
            this.$set(this.emergency_receivers, phones[0].number.toString(), "Unbekannt");
          }

          this.emergency_receiver = phones[0].number.toString();
          this.emergency_receiverId = phones[0].id;
        }

        unwatch = this.$watch('emergency_receiver', async (number, oldNumber) => {
          if(!this.loading) {
            this.loading = true;

            const addFMC = async () => {
              let newPhone = await this.$rest.post("/starface/fmcPhones?userId=1139", { number }, { authToken: this.$parent.auth.authToken });

              if (newPhone) {
                this.emergency_receiverId = newPhone.id;
              } else {
                this.emergency_receiver = oldNumber;
                this.$nextTick(() => { this.loading = false });
              }
              this.loading = false;
            };

            if (this.emergency_receiverId > 0) {
              let result = await this.$rest.delete("/starface/fmcPhones/" + this.emergency_receiverId + "?userId=1139", { authToken: this.$parent.auth.authToken });
              console.log(result);
              if (result != null) {
                if (this.emergency_receivers[oldNumber] == "Unbekannt") {
                  this.$delete(this.emergency_receivers, oldNumber);
                }

                if (number != "") {
                  addFMC();
                } else {
                  this.emergency_receiverId = 0;
                  this.loading = false;
                }
              } else {
                this.emergency_receiver = oldNumber;
                this.$nextTick(() => { this.loading = false });
                console.log("Error removing FmcPhone (" + this.emergency_receiverId + ")");
              }
            } else {
              if(number != "") addFMC();
              else this.loading = false;
            }
          }
        });

        this.loggedIn = true;
        this.loading = false;
      }
    }
  },
  beforeDestroy() {
    unwatch();
    storeUnwatch();
  }
}
</script>

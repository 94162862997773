<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on" :to="{ name: 'defender_client', params: { hostName: client.dNSHostName } }" :disabled="isProtected == 0">
                <v-icon v-if="isProtected == 0" color="gray">mdi-shield-outline</v-icon>
                <v-icon v-else-if="isProtected == 1" color="green">mdi-shield-check</v-icon>
                <v-icon v-else-if="isProtected == 2" color="orange">mdi-shield-half-full</v-icon>
                <v-icon v-else color="red">mdi-shield-alert</v-icon>
            </v-btn>
        </template>
        
        <p v-if="isProtected > 0">
            <b>Stand:</b> {{ statusTime }}<br/>
            <b>Letzter Scan:</b> {{ scanTime }} <span v-if="scanType > 0">({{ scanType == 2 ? "Vollständige Überprüfung" : "Schnellüberprüfung" }})</span>
        </p>
        <p v-if="infoText != ''" v-html="infoText"></p>
    </v-tooltip>
</template>

<script>
export default {
  props: [ "client" ],
  computed: {
    isProtected() {
        // 0: inactive (grey), 1: everything is fine (green), 2: warnings (orange), 3: error/danger (red)

        let state = 0;
        if(this.client && this.client.defender && this.client.defender.date && this.client.defender.status) {
            state = 1;

            if(this.scanType == 1 && this.client.defender.status.QuickScanAge > 2) state = 2;
            else if(this.scanType == 2 && this.client.defender.status.FullScanAge > 2) state = 2;

            if(this.client.defender.status.RebootRequired) state = 2;
            if(this.client.defender.history && JSON.stringify(this.client.defender.history).length > 3) state = 2;

            if(!this.client.defender.status.AntivirusEnabled) state = 3;
            if(!this.client.defender.status.AntispywareEnabled) state = 3;
            if(!this.client.defender.status.RealTimeProtectionEnabled) state = 3;
            if(this.client.defender.detections && JSON.stringify(this.client.defender.detections).length > 3) state = 3;
        }
        
        return state;
    },
    statusTime() {
        if(this.client && this.client.defender && this.client.defender.date) return this.client.defender.date;
        return " --- ";
    },
    scanType() {
        // 0: unknown, 1: quick scan, 2: full scan
        
        if(this.client && this.client.defender && this.client.defender.status) {
            let quickScanTimeISO = new Date("0001-01-01T00:00:00Z");
            let fullScanTimeISO = new Date("0001-01-01T00:00:00Z");

            if(this.client.defender.status.QuickScanStartTimeISO && this.client.defender.status.FullScanStartTimeISO) {
                if(this.client.defender.status.QuickScanStartTimeISO) quickScanTimeISO = new Date(this.client.defender.status.QuickScanStartTimeISO);
                if(this.client.defender.status.FullScanStartTimeISO) fullScanTimeISO = new Date(this.client.defender.status.FullScanStartTimeISO);

                if(quickScanTimeISO > fullScanTimeISO) return 1;
                else return 2;
            } else {
                if(this.client.defender.status.QuickScanStartTimeISO) return 1;
                if(this.client.defender.status.FullScanStartTimeISO) return 2;
            }
        }

        return 0;
    },
    scanTime() {
        if(this.client && this.client.defender && this.client.defender.status) {
            if(this.scanType == 1) return this.client.defender.status.QuickScanStartTime;
            else if(this.scanType == 2) return this.client.defender.status.FullScanStartTime;
        }

        return " --- ";
    },
    infoText() {
        if(this.isProtected == 0) return "Keine Informationen vorhanden";
        if(this.isProtected == 1) return "Keine Bedrohungen vorhanden";

        let info = [];
        
        if(this.scanType == 1 && this.client.defender.status.QuickScanAge > 2) info.push("Letzter Scan älter als 2 Tage");
        else if(this.scanType == 2 && this.client.defender.status.FullScanAge > 2) info.push("Letzter Scan älter als 2 Tage");

        if(this.client.defender.status.RebootRequired) info.push("PC erfordert neustart");
        if(!this.client.defender.status.AntivirusEnabled) info.push("Antivirus deaktiviert");
        if(!this.client.defender.status.AntispywareEnabled) info.push("Antispyware deaktiviert");
        if(!this.client.defender.status.RealTimeProtectionEnabled) info.push("Echtzeitschutz deaktiviert");

        if(this.client.defender.detections && JSON.stringify(this.client.defender.detections).length > 3) info.push("Bedrohungen: " + JSON.stringify(this.client.defender.detections));
        if(this.client.defender.history && JSON.stringify(this.client.defender.history).length > 3) info.push("Verlauf: " + JSON.stringify(this.client.defender.history));

        return info.join("<br/>");
    }
  }
}
</script>